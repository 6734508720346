export const FOLLOW_US = [
  {
    label: "LinkedIn",
    route: "https://www.linkedin.com/company/delta-cognition/",
  },
]

export const FOOTER_CONTENTS = [
  {
    label: "Terms of Use",
    route: "#",
  },
  {
    label: "Privacy Policy",
    route: "#",
  },
  {
    label: "Language",
    route: "English",
  },
  {
    label: "Company",
    route: "© 2023 by Delta Cognition",
  },
]

export const FOOTER_ADDRESS = [
  {
    label: "38 Edwin Close Manor Lakes VIC 3024 Australia",
    country: "Australia",
  },
  {
    label: "436A/139 Street 3/2, Ward 12, District 10, Vietnam",
    country: "Vietnam",
  },
  {
    label: "Flat# 201, Jayasai Srijan Residency, Plot# 313&314, Nizampet village, Hyderabad - 500090, India",
    country: "India",
  },
]
