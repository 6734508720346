import React from "react"
import ScrollContainer from "react-indiana-drag-scroll"
import "./MLApplication.css"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"

import AOS from "aos"
import "aos/dist/aos.css"
import data from "../../../../constants/contents/ml-application.json"

export const MLApplicationCard = (index: number, image: string, title: string, detail: string) => {
  const [open, setOpen] = React.useState(false)
  const closeModal = () => setOpen(false)

  React.useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div
      key={index}
      className={
        "flex flex-col w-[85vw] tablets:w-full " +
        (index % 2 == 0 ? "tablets:flex-row" : "tablets:flex-row-reverse")
      }
    >
      <img
        src={image}
        alt=''
        className={
          "industry-card__image " +
          (index % 2 == 0 ? "tablets:mr-4 small-desktops:mr-8" : "tablets:ml-4 small-desktops:ml-8")
        }
        data-aos='fade-right'
        data-aos-easing='ease'
        data-aos-delay='500'
      />{" "}
      <div className='detail__section'>
        <h3 className='section__title'>{title}</h3>
        <p
          className='section__detail max-h-[260px] overflow-hidden'
          style={{ whiteSpace: "pre-wrap" }}
        >
          {detail}
        </p>
        <button
          type='button'
          className='button px-[10px] py-[3px] border-[1px] rounded-[4px] mt-[14px] mr-[14px]'
          onClick={() => setOpen((o) => !o)}
        >
          Read more
        </button>
      </div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        {/* this tag control the content and title inside the modal */}
        <div className='modal p-[20px] h-full overflow-y-auto'>
          {/* this is the close button */}
          <button
            className='close right-[-15px] absolute px-[10px] py-[3px] top-[-18px] rounded-[50px] bg-black text-white'
            onClick={closeModal}
          >
            &times;
          </button>
          <section className='flex flex-col w-full'>
            {/* this is the title */}
            <h3 className='font-montserrat'>{title}</h3>
            {/* this is the content */}
            <span className='h-full font-open_sans font-normal'> {detail} </span>
          </section>
        </div>
      </Popup>
    </div>
  )
}

const MLApplication = (props: typeof data) => {
  return (
    <div className='industry-container'>
      <section>
        <h2 className='pb-[80px]'>{props.title}</h2>
        <div className='w-full hidden tablets:flex tablets:flex-col tablets:gap-12'>
          {props.contents.map((item, index) => {
            return MLApplicationCard(index, item.image, item.title, item.detail)
          })}
        </div>
        <ScrollContainer className='w-full px-4'>
          <div className='max-w-[1040px] m-auto grid grid-flow-col gap-x-[24px] tablets:hidden'>
            {props.contents.map((item, index) => {
              return MLApplicationCard(index, item.image, item.title, item.detail)
            })}
          </div>
        </ScrollContainer>
      </section>
    </div>
  )
}

export default MLApplication
