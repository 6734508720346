import React from "react"
import "./HomePage.css"
import { OPEN_LANDING_PAGE } from "../../constants/configs"
// Import the scrolling animation
import AOS from "aos"
import "aos/dist/aos.css"

import LandingPage from "../../widgets/v0.1/0.1.1/LandingWidget/LandingWidget"
import Introduction from "../../widgets/v0.1/0.1.1/Introduction/Introduction"
import PowerAI from "../../widgets/v0.1/0.1.1/AIPower/AIPower"
import MLApplication from "../../widgets/v0.1/0.1.1/MLApplication/MLApplication"
// import OurWork from "../../widgets/v0.1/0.1.1/OurWork/OurWork"
// import Question from "../../widgets/v0.1/0.1.1/Question/Question"
import ContactCompany from "../../widgets/v0.1/0.1.1/ContactCompany/ContactCompany"

import introductionData from "../../constants/contents/introduction.json"
import aiPowerData from "../../constants/contents/ai-power.json"
import mlApplicationData from "../../constants/contents/ml-application.json"
// import ourWorkData from "../../constants/contents/our-work.json"
// import questionData from "../../constants/contents/questions.json"

type Props = {}

type States = {}

class HomePage extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  componentDidMount(): void {
    // we init the AOS
    AOS.init()
  }

  render() {
    return (
      <div className='home-page'>
        {OPEN_LANDING_PAGE === "true" && <LandingPage />}
        {OPEN_LANDING_PAGE === "false" && (
          <>
            <hr className='h-[40px] border-none' />
            <Introduction {...introductionData} />
            <hr className='h-[120px] border-none' />
            <div data-aos='fade-up' data-aos-easing='ease' data-aos-delay='200'>
              <PowerAI {...aiPowerData} />
            </div>
            <hr className='h-[120px] border-none' />
            <div
              className='flex justify-center'
              data-aos='fade-up'
              data-aos-easing='ease'
              data-aos-delay='200'
            >
              <MLApplication {...mlApplicationData} />
            </div>
            {/* <hr className='h-[120px] border-none' />
            <div data-aos='fade-up' data-aos-easing='ease' data-aos-delay='200'>
              <OurWork {...ourWorkData} />
            </div>
            <hr className='h-[120px] border-none' />
            <div data-aos='fade-up' data-aos-easing='ease' data-aos-delay='200'>
              <Question {...questionData} />
            </div> */}
            <hr className='h-[120px] border-none' />
            <div data-aos='fade-up' data-aos-easing='ease' data-aos-delay='200'>
              <ContactCompany />
            </div>
            <hr className='h-[120px] border-none' />
          </>
        )}
      </div>
    )
  }
}

export default HomePage
