const API_URL =
  (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_API_URL) ||
  process.env.REACT_APP_API_URL

const OPEN_LANDING_PAGE =
  (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_OPEN_LANDING_PAGE) ||
  process.env.REACT_APP_OPEN_LANDING_PAGE

const EMAIL_SERVICE =
  (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_EMAIL_SERVICE) ||
  process.env.REACT_APP_EMAIL_SERVICE

const EMAIL_TEMPLATE =
  (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_EMAIL_TEMPLATE) ||
  process.env.REACT_APP_EMAIL_TEMPLATE

const EMAIL_KEY =
  (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_EMAIL_KEY) ||
  process.env.REACT_APP_EMAIL_KEY

const NODE_ENV =
  (window.__RUNTIME_CONFIG__ && window.__RUNTIME_CONFIG__.REACT_APP_ENV) ||
  process.env.REACT_APP_ENV

export { API_URL, OPEN_LANDING_PAGE, EMAIL_KEY, EMAIL_SERVICE, EMAIL_TEMPLATE, NODE_ENV }
