import React from "react"
import "./LandingWidget.css"
import {
  COMPANY_DETAIL,
  CONTACTUS,
  DETAIL_POINTS,
  FOOTER,
} from "../../../../constants/strings/landingPageContent"

import emailjs from "@emailjs/browser"
import validator from "validator"
import { EMAIL_SERVICE, EMAIL_TEMPLATE, EMAIL_KEY } from "../../../../constants/configs"

type Props = {}

type States = {
  email: string
  message: string
  messageType: "error" | "success"
  status: "none" | "loading" | "done"
}

class LandingPage extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: "",
      message: "",
      messageType: "success",
      status: "none",
    }

    this.handleSendEmail = this.handleSendEmail.bind(this)
  }

  handleSendEmail = () => {
    if (this.state.email !== "") {
      if (validator.isEmail(this.state.email)) {
        this.setState({ status: "loading" })
        emailjs
          .send(
            EMAIL_SERVICE || "",
            EMAIL_TEMPLATE || "",
            {
              fromName: this.state.email,
            },
            EMAIL_KEY,
          )
          .then(() => {
            this.setState({
              message: "Thank you for your contact. We will reply you soon",
              messageType: "success",
              status: "done",
              email: "",
            })
          })
          .catch(() =>
            this.setState({
              message: "Failed to send contact email. Please contact us by email above",
              messageType: "error",
              status: "none",
              email: "",
            }),
          )
      } else {
        this.setState({
          message: "This is not a valid email address",
          messageType: "error",
          email: "",
        })
      }
    } else
      this.setState({
        message: "Please input your email address !!!",
        messageType: "error",
        email: "",
      })
  }
  render() {
    return (
      <div className='landing-page'>
        <div className='h-auto flex justify-center flex-col items-center small-desktops:flex-row '>
          <div className='header__content'>
            <div className='content__company'>
              <img
                className='relative right-[8px] h-[40px] tablets:h-[50px] small-desktops:h-[64px]'
                src={COMPANY_DETAIL.iconUrl}
                alt='company icon'
              />
            </div>
            <section className='content__detail font-normal'>
              <h1 className='detail__title font-montserrat font-bold text-[32px] regular-desktops:text-[40px] text-black'>
                {COMPANY_DETAIL.slogansTitle}
              </h1>
              <hr />
              {COMPANY_DETAIL.slogansDetail.map((item, index) => {
                return (
                  <p className={index === 0 ? "detail__slogans" : ""} key={index}>
                    {item}
                  </p>
                )
              })}

              <ul className='mt-8'>
                {DETAIL_POINTS.detail.map((item, index) => {
                  return (
                    <li key={index} className='mb-8'>
                      <div className='flex flex-row'>
                        <img src={DETAIL_POINTS.iconUrl} alt='-' className='mr-2' />
                        <p>{item}</p>
                        {index == 0 ? (
                          <p className='ml-1 font-montserrat font-bold text-[#5C3BDE]'>
                            <a href={"mailto:" + COMPANY_DETAIL.email}>
                              <span className='absolute email-underline email-underline-animate-line'>
                                {COMPANY_DETAIL.email}
                              </span>
                              <span>{COMPANY_DETAIL.email}</span>
                            </a>
                          </p>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </li>
                  )
                })}
              </ul>
              <div className='detail__contact-us'>
                <input
                  className='text-[12px] regular-desktops:text-[16px]'
                  type='text'
                  placeholder={CONTACTUS.inputHint}
                  onChange={(event) => {
                    this.setState({
                      email: event.target.value,
                      message: "",
                      messageType: "success",
                    })
                  }}
                  value={this.state.email}
                />
                <div></div>
                <button
                  className='absolute right-0 flex items-center mr-3 h-full text-[12px] regular-desktops:text-[16px]'
                  onClick={this.handleSendEmail}
                  disabled={this.state.status === "loading"}
                >
                  {this.state.status === "loading" && (
                    <svg
                      className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  )}
                  {CONTACTUS.buttonText}
                </button>
              </div>
              <p
                className={[
                  "ml-2",
                  this.state.messageType === "error" ? "text-red-500" : "text-green-500",
                ].join(" ")}
              >
                {this.state.message}
              </p>
            </section>
          </div>
          <div className='header__image'>
            <img src={COMPANY_DETAIL.contentUrl} alt='' />
          </div>
        </div>
        <div className='app__footer'>
          <a href={FOOTER.link}>
            <img className='mb-8 w-8 h-8' src={FOOTER.iconUrl} alt='linkedin' />
          </a>
          <p className='footer__content'>{FOOTER.detail}</p>
          <hr className='w-[1120px]' />
          <p className='text-black mt-8 font-light text-[11px] regular-desktops:text-[14px]'>
            {FOOTER.copyRight}
          </p>
        </div>
      </div>
    )
  }
}

export default LandingPage
