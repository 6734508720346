import React, { useState } from "react"
import "./Wrapper.css"
import Popup from "reactjs-popup"
import "reactjs-popup/dist/index.css"

interface WrapperProps {
  iconUrl: string
  title: string
  detail: string
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  image: any
}

const Wrapper = (props: WrapperProps) => {
  // used to control close and open for modal
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)

  const Modal = (title: string, content: string) => {
    return (
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        {/* this tag control the content and title inside the modal */}
        <div className='modal p-[20px] h-full overflow-y-auto'>
          {/* this is the close button */}
          <button
            className='close right-[-15px] absolute px-[10px] py-[3px] top-[-18px] rounded-[50px] bg-black text-white'
            onClick={closeModal}
          >
            &times;
          </button>
          <section className='flex flex-col w-full'>
            {/* this is the title */}
            <h3 className='font-montserrat'>{title}</h3>
            {/* this is the content */}
            <span className='h-full font-open_sans'> {content} </span>
          </section>
        </div>
      </Popup>
    )
  }

  return (
    <div className='wrapper' onClick={() => setOpen((o) => !o)}>
      <div className='wrapper__icon-cover'>
        <img src={props.image} alt='icon' className='w-12' />
      </div>
      <p className='wrapper__title'>{props.title}</p>
      <div className='popup-container'>
        <div className='popup-container__button'></div>
        {Modal(props.title, props.detail)}
      </div>
    </div>
  )
}

export default Wrapper
